//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {

  > li {
    > a,
    > span {
      > i {
          line-height:0;
      }
    }
  }
}

