[class*="titip"] {
    position: relative;
    display: inline-block;
    cursor:help;

    &[data-title]:before, 
    &[data-title]:after,
    .titip-content {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: @zindex-titip;
        .translate3d(0,0,0);
    }
}
[class*="titip"][data-title]:after,
.titip-content {
    background-color: @titip-bg;
    color: @titip-color;
    width:auto;
}
[class*="titip"][data-title],
.titip-content {
     &:before {
      content: '';
      border: @titip-tringle-width solid @titip-bg;
      width:0;
      height:0;
      padding:0;
      margin:0;
    }
}
* .titip-hidden[class*="titip"][data-title]:before, 
* .titip-hidden[class*="titip"][data-title]:after,
* .titip-hidden .titip-content {
  visibility: hidden !important;
  opacity: 0  !important;
  filter: alpha(opacity=0)  !important;
}
.titip-content {
    border:1px solid @titip-border-color;
    &:before,
    &:after {
      position: absolute;
      z-index: ceil((@zindex-titip + 1));
    }
    &:after {
      z-index: @zindex-titip;
      content: '';
      border-style:solid;
      border-color:inherit;
      border-width: @titip-tringle-width;
      width:0;
      height:0;
      padding:0;
      margin:0;
    }
    &.thick-border {
      border-width:4px;
    }
}
[class*="titip"][data-title]:after,
.titip-content {
  padding: @titip-padding;
  font-size: @titip-font-size;
  line-height: @titip-line-height;
  text-shadow:none;
  white-space: nowrap;
  border-radius:@titip-radius;
  .box-shadow(@titip-box-shadow);
}
.titip-xs[data-title]:after,
.titip-xs .titip-content {
    padding: 3px 5px 2px;
}
[data-title][class*="titip"]:after {
  content: attr(data-title);
}
.titip-active,
[class*="titip"]:hover,
[class*="titip"]:active {
    .titip-content,
    &[data-title]:before,
    &[data-title]:after{
        opacity: 1;
        visibility: visible;
        filter: alpha(opacity=100);
    }
}

[class*="titip-top"],
[class*="titip-bottom"]{
    > .titip-content,
    &[data-title]:before,
    &[data-title]:after{
        left: 50%;
        .translateX(-50%)
    }
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
    }
    > .titip-content:before {
         @margin:ceil((@titip-tringle-width / 2));
        left:50%;
        margin-left:-@margin;
    }
    > .titip-content:after {
        @margin:ceil(((@titip-tringle-width / 2) + 2px));
        left:50%;
        margin-left:-@margin;
    }
    > .titip-content.thick-border:after {
        margin-left:-9px;
    }
}
.titip[data-title]:before, 
.titip[data-title]:after,
.titip > .titip-content  {
  top:100%;
  margin-top:ceil(((@titip-tringle-width / 2) + 1px));
  margin-left:50%;
}
[class*="titip-top"]{
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
      border-bottom-color: transparent !important;
      border-width:@titip-tringle-width ceil((@titip-tringle-width / 2)) 0;
    }
    > .titip-content:after {
      border-width:ceil((@titip-tringle-width + 1px)) ceil(((@titip-tringle-width / 2) + 2px)) 0;
    }
    > .titip-content,
    &[data-title]:after,
    &[data-title]:before {
      bottom: 100%;
    }
    &[data-title]:before {
        margin-bottom: 2px;
    }
    > .titip-content,
    &[data-title]:after {
        margin-bottom: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
        bottom: -@titip-tringle-width;
    }
    > .titip-content:after {
        @margin:ceil((@titip-tringle-width + 2px));
        bottom: -@margin;
    }
    > .titip-content.thick-border {
       margin-bottom: 16px;
    }
    > .titip-content.thick-border:before {
       border-width:10px 5px 0;
       bottom: -10px;
    }
    > .titip-content.thick-border:after {
       border-width:15px 10px 0;
       bottom: -15px;
    }
}

[class*="titip-bottom"]{
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
      border-top-color: transparent !important;
      border-width:0 ceil((@titip-tringle-width / 2)) @titip-tringle-width;
    }
    > .titip-content:after {
      border-width:0 ceil(((@titip-tringle-width / 2) + 2px)) ceil((@titip-tringle-width + 1px));
    }
    > .titip-content,
    &[data-title]:after,
    &[data-title]:before {
      top: 100%;
    }
    &[data-title]:before {
        margin-top: 2px;
    }
    > .titip-content,
    &[data-title]:after {
        margin-top: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
        top: -@titip-tringle-width;
    }
    > .titip-content:after {
        @margin:ceil((@titip-tringle-width + 2px));
        top: -@margin;
    }
    > .titip-content.thick-border {
       margin-top: 13px;
    }
    > .titip-content.thick-border:before {
        border-width:0 5px 10px;
        top: -10px;
    }
    > .titip-content.thick-border:after {
       border-width:0 10px 15px;
       top: -15px;
    }
}
[class*="titip-top-left"],
[class*="titip-bottom-left"],
[class*="titip-top-right"],
[class*="titip-bottom-right"] {
    > .titip-content,
    &[data-title]:before,
    &[data-title]:after {
        .translateX(0%);
    }
    
}

[class*="titip-top-left"], 
[class*="titip-bottom-left"] {
    &[data-title]:before,
    &[data-title]:after,
    > .titip-content {
      left: auto;
      right:0;
    }
    &[data-title]:before {
      right: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
        left:auto;
        right: ceil((@titip-tringle-width + 6px));
    }
    > .titip-content:after {
        left:auto;
        right: ceil((@titip-tringle-width + 4px));
    }
    > .titip-content.thick-border:after {
        right: 9px;
    }
}
[class*="titip-top-right"], 
[class*="titip-bottom-right"] {
    &[data-title]:before,
    &[data-title]:after,
    > .titip-content {
      right: auto;
      left:0;
    }
    &[data-title]:before {
      left: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
      left: ceil((@titip-tringle-width + 6px));
    }
    > .titip-content:after {
      left: ceil((@titip-tringle-width + 6px));
    }
}
[class*="titip-left"],
[class*="titip-right"]{
    > .titip-content,
    &[data-title]:before,
    &[data-title]:after{
        top: 50%;
        .translateY(-50%)
    }
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
    }
    > .titip-content:before {
        @margin:ceil((@titip-tringle-width / 2));
        top:50%;
        margin-top:-@margin
    }
    > .titip-content:after {
        @margin:ceil(((@titip-tringle-width / 2) + 2px));
        top:50%;
        margin-top:-@margin
    }
    > .titip-content.thick-border:after {
        @margin:ceil(((@titip-tringle-width / 2) + 5px));
      margin-top:-@margin
    }
}
[class*="titip-left"]{
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
      border-right-color: transparent !important;
      border-width:ceil((@titip-tringle-width / 2)) 0 ceil((@titip-tringle-width / 2)) @titip-tringle-width;
    }
    > .titip-content:after {
      border-width:ceil(((@titip-tringle-width / 2) + 2px))  0 ceil(((@titip-tringle-width / 2) + 2px)) ceil((@titip-tringle-width + 1px));
    }
    > .titip-content,
    &[data-title]:after,
    &[data-title]:before {
      right: 100%;
    }
    &[data-title]:before {
        margin-right: 2px;
    }
    > .titip-content,
    &[data-title]:after {
        margin-right: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
        right: -@titip-tringle-width;
    }
    > .titip-content:after {
        @margin:ceil((@titip-tringle-width + 2px));
        right: -@margin;
    }
    > .titip-content.thick-border {
      margin-right: 16px;
    }
    > .titip-content.thick-border:before {
      border-width:5px 0px 5px 10px;
      right: -10px;
    }
    > .titip-content.thick-border:after {
      border-width:10px 0px 10px 15px;
      right: -15px;
    }
}
[class*="titip-right"]{
    > .titip-content:before,
    > .titip-content:after,
    &[data-title]:before {
      border-left-color: transparent !important;
      border-width:ceil((@titip-tringle-width / 2)) @titip-tringle-width ceil((@titip-tringle-width / 2)) 0;
    }
    > .titip-content:after {
      border-width:ceil(((@titip-tringle-width / 2) + 2px))  ceil((@titip-tringle-width + 1px)) ceil(((@titip-tringle-width / 2) + 2px)) 0;
    }
    > .titip-content,
    &[data-title]:after,
    &[data-title]:before {
      left: 100%;
    }
    &[data-title]:before {
        margin-left: 2px;
    }
    > .titip-content,
    &[data-title]:after {
        margin-left: ceil((@titip-tringle-width + 2px));
    }
    > .titip-content:before {
        left: -@titip-tringle-width;
    }
    > .titip-content:after {
        @margin:ceil((@titip-tringle-width + 2px));
        left: -@margin;
    }
    > .titip-content.thick-border {
      margin-left: 16px;
    }
    > .titip-content.thick-border:before {
      border-width:5px 10px 5px 0px;
      left: -10px;
    }
    > .titip-content.thick-border:after {
      border-width:10px 15px 10px 0px;
      left: -15px;
    }
}

.titip-wrapped:after,
.titip-wrapped > .titip-content {
  height: auto;
  width: 170px !important;
  padding: 11px;
  line-height: 19px;
  white-space: normal !important;
  text-align: left;
}
.titip-wrapped-large:after,
.titip-wrapped-large > .titip-content {
  height: auto;
  width: 300px;
  padding: 11px;
  line-height: 19px;
  white-space: normal !important;
  text-align: left;
}