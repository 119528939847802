//
// Carousel QuantumUI styles
// --------------------------------------------------

.carousel {
  position: relative;
  margin-left:auto;
  margin-right:auto;
  max-width:100%;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;

  > .item {
    position: absolute;
    width:100%;
    height:100%;
    overflow:hidden;
  }

}

.carousel-indicators {
  li {
    text-indent: -9999px;
  }
}

.carousel-title{
    position: absolute;
    left:0;
    right:0;
    top:0;
    height:45px;
    line-height:45px;
    white-space:nowrap;
    overflow:hidden;
    font-size:22px;
    font-weight:bold;
    margin:0;
    padding-left:20px;
    color: #ffffff !important;
    background:rgba(0,0,0, 0.4)
}

/*number navigation*/
.carousel.number-navigation,
.carousel.thumb-navigation {
    padding:2px;
    padding-bottom:30px;
    border: 1px solid @carousel-indicator-thumb-border-color;
    border-radius:4px;
   .carousel-indicators {
      bottom: 0;
      left: 0;
      z-index: 15;
      width: 100%;
      padding: 2px;
      margin: 0;
      background-color:@carousel-indicator-thumb-bg;
      #gradient > .vertical(@start-color: tint(@carousel-indicator-thumb-bg, 4%); @end-color: @carousel-indicator-thumb-bg;);
      
        > li{
            width: 30px;
            height: 30px;
            margin: 1px;
            text-indent: 0;
            border-radius:4px;
            opacity:0.4;
            #gradient > .elipse(@inner-color: tint(@carousel-indicator-thumb-bg, 4%); @outer-color: shade(@carousel-indicator-thumb-bg, 6%););
       }
        .active {
            opacity:1;
         }
       
   }
    .indicator-no {
        display:block;
        font-size:16px;
        font-weight:bold;
        line-height:30px;
        color:@carousel-indicator-thumb-color;
    }
    .carousel-caption {
        right: 0;
        bottom: 0;
        left: 0;
        color: #ffffff;
        text-align: justify;
        padding:10px;
        border-top: 1px solid @carousel-indicator-thumb-border-color;
        background-color:fade(@carousel-caption-bg, 50%);
    }
}

/*thumb navigation*/
.carousel.thumb-navigation {
    padding-bottom:5px;
    .carousel-indicators {
      overflow:hidden;
      height:54px;
      > li{
          width: 70px;
          height: 50px;
          line-height:50px;
          margin: 1px;
          margin-bottom:3px;
          text-indent: 0;
          border-radius:0;
          opacity:0.6;
      }
    }
    .indicator-no {
        display:block;
    }
    .carousel-caption {
      bottom: 50px;
      color: #ffffff;
    }
}

@media (max-width: @screen-xs-max) {

  .carousel-caption {
    left: 10%;
    right: 10%;
    bottom:0;
    padding-bottom: 0;
    font-size:0.6em;
    h4{
        font-size:12px;
    }
  }
}